// This file is generated via scripts/build-static-config.js
// Do not modify directly as changes will be overwritten in CI

export const CONFIGURATION = {
  country: 'ca',
  brand: 'bk',
  env: 'prod',
  graphqlEnv: 'prod',
  graphqlGatewayEnv: '',
  sanityEnv: 'prod',
  platform: 'web',
  commitRef: 'f7dfe1f4a9083ad462406a8ef90ea1c6dd3599f9',
  releaseTimestamp: '1681306693',
  sanityDataset: '',
  sanityProjectId: 'czqk28jt',
  appFlowBuildId: '',
  adyen: {
    clientKey: 'live_L6BRV3FK4FFZVN2XFXYC6WCMRALGGK6O',
    environment: 'live-us',
    urlPrefix: 'https://checkoutshopper-live.adyen.com',
  },
  apiKeys: {
    amplitude: '9173edc6287c7687c01a9d1819b5baae',
    branch: 'key_live_ajPWhsHa6RXLEPc89JHAqdpltxo2q1lC',
    braze: '896c405d-24f0-4e66-af86-3d2192f5462e',
    bringg: '',
    googleMaps: 'AIzaSyB1spfGoKUYsoqp619LQ2rSF8G2XE337Uc',
    googleAnalytics: '',
    tomtom: '',
    launchDarkly: '5ebebd12d8ad2c0a4dd27dd4',
    launchDarklyMobileKey: 'mob-fc834735-ae77-45eb-8ecb-990b5e23f3a7',
    logRocket: 'mj7uvx/ctg-prod',
    mParticle: 'us1-dd204cbf99da8243aa9473257c44c1a9',
    radar: 'prj_live_pk_62fa5ae91a3006af82f2c19794c7aadb4716dd',
  },
  apple: {
    merchantId: 'merchant.com.bk.native.adyen.prod',
    migrationMerchantId: 'merchant.com.bk.ca.native.orbital.prod',
    paymentsNetworks: ['AmEx','MasterCard','Visa','Discover'],
    prepaidMerchantId: '',
    migrationPrepaidMerchantId: '',
  },
  aws: {
    gqlApiUrl: 'https://use1-prod-bk.rbictg.com/graphql',
    gqlGatewayApiUrl: 'https://use1-prod-bk-gateway.rbictg.com/graphql',
    region: 'us-east-1',
    userPoolClientId: '1ca8tuisf8vnaffhfck2258idt',
    userPoolId: 'us-east-1_BCVUfvjft',
  },
  bringg: {
    region: '6',
  },
  datadog: {
    logs: {
      clientToken: 'pubf86c7fbda9db5464a9cf21d924cd74db',
      dataCenter: '',
    },
    rum: {
      applicationId: 'cf8d1c33-af85-481b-b4b7-ce00a7ec999d',
      clientToken: 'pubf86c7fbda9db5464a9cf21d924cd74db',
      dataCenter: '',
    },
  },
  forter: {
    siteId: 'cd86966ceeb2',
  },
  google: {
    paymentsEnvironment: 'prod',
    paymentsGateway: 'adyen',
    paymentsGatewayMerchantId: {
      ca: 'RestaurantBrandsInternational',
      us: '',
    },
    migrationPaymentsGateway: 'chase',
    migrationPaymentsGatewayMerchantId: {
      ca: '521980',
      us: '',
    },
    paymentsNetworks: ['AMEX','MASTERCARD','VISA','DISCOVER','JCB'],
    prepaidPaymentsGateway: '',
    prepaidPaymentsGatewayMerchantId: {
      ca: '',
      us: '',
    },
    migrationPrepaidPaymentsGateway: '',
    migrationPrepaidPaymentsGatewayMerchantId: {
      ca: '',
      us: '',
    }
  },
  googleAdManager: {
    campaignId: '',
  },
  googleTagManager: {
    containerId: '',
  },
  oneTrust: {
    cookieCompliance: {
      consentCategoryBranch: 'C0004',
      consentCategoryBraze: 'C0002',
      consentCategoryMParticle: 'C0001',
      globalConsentState: 'Opt-In',
      scriptUrl: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
      scriptId: '720276cf-32bf-4e23-8b79-55e7d7e6e33f',
    },
    deleteAccountUrl: '',
  },
  pypestream: {
    scriptUrl: '',
    appId: '',
  },
  restaurants: {
    displayEnv: 'prod',
    validMobileOrderingEnvs: ['alpha','beta','live'],
  },
  urls: {
    braze: 'https://customer.iad-03.braze.com/api/v3',
    firstData: 'https://api.payeezy.com',
    firstDataTh: '',
    webBaseUrl: '',
    thDigital: '',
  },
  tokenEx: {
    publicKey: '',
  },
  vrPayment: {
    braintreeTokenizationKey: '',
  },
  orbital: {
    baseUrl: 'https://safetechpageencryption.chasepaymentech.com',
    subscriberId: '750000001767',
    pieFormat: '04',
    mode: 'FPE',
  },
  timeFormat: '',
  dateFormat: '',
  appStoreDeeplink: '',
};